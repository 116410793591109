import React from 'react';

import { CHECKMARK } from '@/components/switchback/Icon/assets';
import Icon from '@/components/switchback/Icon/IconComponent';
import { generateSimpleId } from '@/utility/helpers';

import css from './RadioBox.module.css';

type IRadioBoxElement = React.InputHTMLAttributes<HTMLInputElement>;

interface IProps {
  label: React.ReactNode;
  className?: string;
  labelClassName?: string;
}

const RadioBox = React.forwardRef<HTMLInputElement, IProps & IRadioBoxElement>(
  (
    { children, disabled, id, label, name, value, labelClassName = '', className = '', ...props },
    ref,
  ) => {
    const inputId = id || generateSimpleId();

    const getTestId = () => {
      if (disabled) return `${name}-btn-disabled`;
      return `${name}-btn`;
    };

    return (
      <div className={className}>
        <input
          // For some reason react does not keep input value in sync with passed value
          // https://github.com/facebook/react/issues/21801
          key={props.checked ? `${inputId}-checked` : `${inputId}-unchecked`}
          className={`fixed opacity-0 pointer-events-none ${css.input}`}
          type="radio"
          id={inputId}
          name={name}
          value={value}
          ref={ref}
          disabled={disabled}
          {...props}
        />
        <label
          className={`border-solid border-2 rounded-box p-5 cursor-pointer text autoType300 relative text-gray-500 inline-block w-full ${css.container} ${labelClassName}`}
          htmlFor={inputId}
          data-disabled={disabled}
          data-test-id={getTestId()}>
          <span
            className={`autoType400 block highlight mb-2 text-gray-900 cursor-pointer ${css.label}`}>
            {label}
          </span>
          <span aria-hidden className={`absolute text-gray-800 top-5 right-5 hidden ${css.icon}`}>
            <Icon name={CHECKMARK} height={16} width={16} />
          </span>
          {children}
        </label>
      </div>
    );
  },
);

RadioBox.displayName = 'RadioBox';

export default RadioBox;
