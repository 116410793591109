import { TIconName } from '@outdoorsyco/bonfire';
import dayjs from 'dayjs';
import { createSelector } from 'reselect';

import {
  CHECKIN_CHECKOUT,
  GENERATOR,
  MANAGE_VEHICLES,
  MILEAGE,
  PARKING_ONSITE,
} from '@/components/switchback/Icon/assets';
import { IconType } from '@/components/switchback/Icon/IconComponent';
import { TCurrency } from '@/config/locales';
import { EHouseRateCards, EHouseRates, EHouseRules, EStayHouseRules } from '@/constants/houseRules';
import { TRootState } from '@/redux/rootReducer';
import {
  IFeatures,
  IStayFeatures,
  IUsageItem,
  IUsageTier,
} from '@/services/types/search/rentals/id';
import { formatCurrency } from '@/utility/currency';
import { getIntl } from '@/utility/i18n';
import { parse24HFormatTimeToSeconds } from '@/utility/parse24HFormatTimeToSeconds';
import { mapPluralUnitToSingular, pluralizeUnit } from '@/utility/units';

import { getCurrency } from '../currency';
import { getListingPriceType, PriceType } from './page';

type TListingData = TRootState['listing']['data'];
type TUsageType = 'generator' | 'mileage';

export interface IUsageRules {
  title: string;
  tooltip?: string;
  tiers?: string[];
  free?: boolean;
  icon: IconType;
}

export interface ICampsiteHouseRules {
  title: string;
  icon: TIconName;
  notAllowed?: boolean;
}

interface IRates {
  title: string;
  subTitle: string;
}

interface IRateCards {
  label: string;
  title: string;
  subtitle?: string;
}

const mapDataToListingHouseRules = (
  data: TListingData,
  currency: TCurrency | undefined,
  priceBy: PriceType,
) => {
  if (!data) {
    return null;
  }

  const {
    active_options: {
      day_price,
      weekly_rate_per_day,
      minimum_days: activeMinimumDays,
      weekly_discount_percentage,
      monthly_rate_per_day,
      monthly_discount_percentage,
    },
    cancel_policy,
    cancel_text,
    house_rules,
    host_notes,
    minimum_days,
    security_deposit,
    prep_fee,
  } = data;

  const houseRulesText = data.rental_category === 'stay' ? host_notes || '' : house_rules;

  const currencyFormat = { currency, digits: 2 };

  const intl = getIntl();

  const minimumDays = activeMinimumDays || minimum_days;

  const rates: Record<EHouseRates, IRates | undefined> = {
    [EHouseRates.MINIMUM_STAY]: minimumDays
      ? {
          title: intl.formatMessage({
            defaultMessage: 'Minimum stay',
            id: 'ARghAs',
          }),
          subTitle: intl.formatMessage(
            {
              defaultMessage: `{minimumDays, number} {priceBy, select,
                day {{minimumDays, plural, one {day} other {days}}}
                other {{minimumDays, plural, one {night} other {nights}}}
              }`,
              id: '+0O/jx',
            },
            { minimumDays, priceBy },
          ),
        }
      : undefined,
    [EHouseRates.SECURITY_DEPOSIT]: security_deposit
      ? {
          title: intl.formatMessage({
            defaultMessage: 'Security deposit (refundable)',
            id: 'AkoJ7T',
          }),
          subTitle: formatCurrency({ priceInCents: security_deposit, ...currencyFormat }),
        }
      : undefined,
  };

  const rateCards: Record<EHouseRateCards, IRateCards> = {
    [EHouseRateCards.DAILY]: {
      label: intl.formatMessage({
        defaultMessage: 'Daily',
        id: 'zxvhnE',
      }),
      title: intl.formatMessage(
        {
          defaultMessage: '{price}{nl}/{priceBy}',
          id: 'gm/k/i',
        },
        {
          nl: '\n',
          price: formatCurrency({ priceInCents: day_price, ...currencyFormat }),
          priceBy,
        },
      ),
    },
    [EHouseRateCards.WEEKLY]: {
      label: intl.formatMessage({
        defaultMessage: 'Weekly',
        id: '/clOBU',
      }),
      title: intl.formatMessage(
        {
          defaultMessage: '{price}{nl}/{priceBy}',
          id: 'gm/k/i',
        },
        {
          nl: '\n',
          price: formatCurrency({ priceInCents: weekly_rate_per_day, ...currencyFormat }),
          priceBy,
        },
      ),
      subtitle: intl.formatMessage(
        {
          defaultMessage: '{percent}% discount',
          id: 'Ps54Mx',
        },
        {
          percent: weekly_discount_percentage,
        },
      ),
    },
    [EHouseRateCards.MONTHLY]: {
      label: intl.formatMessage({
        defaultMessage: 'Monthly',
        id: 'wYsv4Z',
      }),
      title: intl.formatMessage(
        {
          defaultMessage: '{price}{nl}/{priceBy}',
          id: 'gm/k/i',
        },
        {
          nl: '\n',
          price: formatCurrency({ priceInCents: monthly_rate_per_day, ...currencyFormat }),
          priceBy,
        },
      ),
      subtitle: intl.formatMessage(
        {
          defaultMessage: '{percent}% discount',
          id: 'Ps54Mx',
        },
        {
          percent: monthly_discount_percentage,
        },
      ),
    },
  };

  return {
    cancel_policy,
    cancel_text,
    house_rules: houseRulesText,
    rates,
    rateCards,
    prep_fee,
    security_deposit,
    weekly_discount_percentage,
    monthly_discount_percentage,
  };
};

const mapDataToListingUsageRules = (
  data: TListingData,
  currency: TCurrency | undefined,
  type: TUsageType,
  totalIncluded: number,
  includeTiers?: boolean,
): IUsageRules | null => {
  if (!data) {
    return null;
  }

  const key: `${TUsageType}_usage_item` = `${type}_usage_item`;
  const usageItem = data[key];
  const { included, tiers, unlimited, unit } = usageItem;

  const intl = getIntl();

  // no usage data
  if ((included === 0 && !unlimited && !tiers?.length) || !unit) {
    return null;
  }

  const icon = {
    generator: GENERATOR,
    mileage: MILEAGE,
  }[type];

  // unlimited usage
  if (unlimited) {
    return {
      title: {
        generator: intl.formatMessage(
          { defaultMessage: 'Unlimited generator {unit}s', id: 'cTIue9' },
          { unit },
        ),
        mileage: intl.formatMessage({
          defaultMessage: 'Unlimited mileage',
          id: 'A+ps4g',
        }),
      }[type],
      icon,
      free: true,
    };
  }

  const title = buildTitleForUsage(usageItem, totalIncluded, type);
  const additionalTiers = buildTiersForMileage(usageItem, currency);
  const tooltip = buildTooltipForUsage(usageItem, currency, type, additionalTiers, includeTiers);

  return {
    title,
    tooltip,
    icon,
    tiers: additionalTiers,
    free: included > 0,
  };
};

const buildTitleForUsage = (data: IUsageItem, totalIncluded: number, type: TUsageType) => {
  if (!data) return '';

  const intl = getIntl();
  const { unit, included, included_period } = data;

  const includedPeriod = mapPluralUnitToSingular(
    unit !== included_period ? included_period : 'day',
  );

  const totalIncludedToTrip = {
    mileage: intl.formatMessage(
      { defaultMessage: '{totalIncluded} trip {units} included', id: '5HRhQE' },
      { totalIncluded, units: pluralizeUnit(totalIncluded, unit) },
    ),
    generator: intl.formatMessage(
      { defaultMessage: '{totalIncluded} generator {units} included', id: 'qSNTkj' },
      { totalIncluded, units: pluralizeUnit(totalIncluded, unit) },
    ),
  }[type];

  return {
    mileage: totalIncluded
      ? totalIncludedToTrip
      : included
        ? intl.formatMessage(
            {
              defaultMessage: 'Mileage: {included} free {units} / {included_period}',
              id: 'wAL6MN',
            },
            {
              included,
              units: pluralizeUnit(included, unit),
              included_period: includedPeriod,
            },
          )
        : intl.formatMessage({
            defaultMessage: 'Mileage: No mileage included',
            id: 'LZmz+z',
          }),
    generator: totalIncluded
      ? totalIncludedToTrip
      : intl.formatMessage(
          {
            defaultMessage:
              '{included, select, 0 {No} other {{included}}} generator {units} {included, select, undefined {included} other {/ {included_period}}}',
            id: 'RR6zaR',
          },
          {
            included: included,
            units: pluralizeUnit(included, unit),
            included_period: includedPeriod,
          },
        ),
  }[type];
};

const buildTooltipForUsage = (
  data: IUsageItem,
  currency: TCurrency | undefined,
  type: TUsageType,
  additionalTiers: string[] | undefined,
  includeTiers?: boolean,
) => {
  if (!data) return '';
  const { included, unit, tiers } = data;

  if (!tiers) {
    return '';
  }

  const intl = getIntl();
  const units = pluralizeUnit(included, unit);

  // subTitle for tiered data
  if (additionalTiers) {
    const generator = intl.formatMessage(
      {
        defaultMessage:
          '{included, select, 0 {For each hour of use, you will be charged.} other {If you exceed the included {units} you will be charged.}}',
        id: '+I/W/9',
      },
      { included, units },
    );
    if (includeTiers) {
      return {
        mileage: intl.formatMessage(
          {
            defaultMessage:
              'You will be charged additional fees for the following extra {units}: {tiers}.',
            id: 'xCqPon',
          },
          { tiers: additionalTiers.join('. '), units },
        ),
        generator,
      }[type];
    } else {
      return {
        mileage: intl.formatMessage({
          defaultMessage: 'You will be charged additional fees if you go over.',
          id: '5fG8gC',
        }),
        generator,
      }[type];
    }
  }

  // @ts-expect-error fixable: unchecked index access
  const formattedPrice = formatCurrency({ priceInCents: tiers[0].price, currency, digits: 2 });

  return {
    mileage: included
      ? intl.formatMessage(
          {
            defaultMessage: 'You will be charged {price} for each additional {singular_unit}.',
            id: 'EwpInD',
          },
          {
            price: formattedPrice,
            singular_unit: mapPluralUnitToSingular(unit),
          },
        )
      : '',
    generator: intl.formatMessage(
      {
        defaultMessage: 'You will be charged {price} for each additional {unit}.',
        id: 'bF8jVL',
      },
      {
        price: formattedPrice,
        unit,
      },
    ),
  }[type];
};

const buildTiersForMileage = (
  data: IUsageItem,
  currency: TCurrency | undefined,
): string[] | undefined => {
  if (!data) {
    return undefined;
  }

  const { unit, included, tiers } = data;

  // not tiered
  if (!tiers || tiers.length <= 1) {
    return undefined;
  }

  const intl = getIntl();
  // iterate through tiers while keeping track of minimum
  let currentMin = included + 1; // starting minimum value is whatever is included
  return tiers
    .sort(function (a: IUsageTier, b: IUsageTier) {
      return a.maximum - b.maximum;
    })
    .map((tier: IUsageTier, index) => {
      const isLast = index === tiers.length - 1;
      const { minimum_fee, price } = tier;

      const charge = formatCurrency({ priceInCents: price ?? minimum_fee, currency, digits: 2 });
      // example return value:`2-6: $1 per mile`
      // example last item :  `7+: $1 per mile`
      const returnValue = intl.formatMessage(
        {
          defaultMessage: `{min}{isLast, select,
            true {+}
            other {-{max}}} {plural_unit}: {price} / {singular_unit}`,
          id: '4e8o+M',
        },
        {
          min: currentMin,
          max: tier.maximum,
          price: charge,
          singular_unit: mapPluralUnitToSingular(unit),
          plural_unit: pluralizeUnit(currentMin, unit),
          isLast,
        },
      );

      // set new minimum
      currentMin = tier.maximum + 1;
      return returnValue;
    });
};

export const getListingFeatures = createSelector<
  TRootState,
  IFeatures | undefined,
  Partial<IFeatures>
>(
  state => state.listing.data?.features,
  data => {
    if (!data) {
      return {};
    }

    return Object.values(EHouseRules).reduce((features: Partial<IFeatures>, rule) => {
      features[rule as EHouseRules] = data[rule];
      return features;
    }, {});
  },
);

export const getStayListingFeatures = createSelector<
  TRootState,
  IStayFeatures | undefined,
  Partial<IFeatures>
>(
  state => state.listing.data?.stay?.features,
  data => {
    if (!data) {
      return {};
    }

    return Object.values(EStayHouseRules).reduce((features: Partial<IFeatures>, rule) => {
      features[rule as EStayHouseRules] = data[rule];
      return features;
    }, {});
  },
);

export const getListingHouseRules = createSelector<
  TRootState,
  TListingData,
  ReturnType<typeof getCurrency>,
  ReturnType<typeof getListingPriceType>,
  ReturnType<typeof mapDataToListingHouseRules>
>(state => state.listing.data, getCurrency, getListingPriceType, mapDataToListingHouseRules);

export const getStayHouseRules = createSelector(
  [(state: TRootState) => state.listing.data],
  listing => {
    if (!listing) {
      return null;
    }

    const intl = getIntl();

    const checkIn = listing.check_in;
    const checkOut = listing.check_out;
    const checkinType = listing.stay?.check_in_type;

    if (checkIn == null || checkOut == null) return null;

    const lineItems = [];

    if (checkIn) {
      lineItems.push({
        title: intl.formatMessage(
          {
            defaultMessage: 'Check in: {value}',
            id: 'netgzF',
          },
          { value: intl.formatTime(dayjs().startOf('day').add(checkIn, 's').toDate()) },
        ),
        icon: CHECKIN_CHECKOUT,
      });
    }
    if (checkOut) {
      lineItems.push({
        title: intl.formatMessage(
          {
            defaultMessage: 'Check out: {value}',
            id: 'w3caJO',
          },
          {
            value: intl.formatTime(dayjs().startOf('day').add(checkOut, 's').toDate()),
          },
        ),
        icon: CHECKIN_CHECKOUT,
      });
    }

    if (checkinType) {
      lineItems.push({
        title:
          checkinType === 'self'
            ? intl.formatMessage({
                defaultMessage: 'Self check-in',
                id: 'lY14zC',
              })
            : intl.formatMessage({
                defaultMessage: 'Check in with the host',
                id: '7MjqmO',
              }),
        icon: MANAGE_VEHICLES,
      });
    }

    return lineItems;
  },
);

export const getCampsiteHouseRules = (state: TRootState) => {
  if (!state.listing.data) {
    return null;
  }
  const intl = getIntl();

  const checkIn = parse24HFormatTimeToSeconds(state.listing.data.campground?.check_in_time);
  const checkOut = parse24HFormatTimeToSeconds(state.listing.data.campground?.check_out_time);
  const allowsPets = state.listing.data.campground?.features?.allows_pets;
  const allowsRvs = state.listing.data.campground?.features?.allows_rv;
  const allowsCampfires = state.listing.data.campground?.features?.allows_campfires;
  const sleeps = state.listing.data.stay?.sleeps || state.listing.data.sleeps;

  if (checkIn == null || checkOut == null) return null;

  const lineItems: ICampsiteHouseRules[] = [];

  if (checkIn) {
    lineItems.push({
      title: intl.formatMessage(
        {
          defaultMessage: 'Check in: {value}',
          id: 'netgzF',
        },
        { value: intl.formatTime(dayjs().startOf('day').add(checkIn, 's').toDate()) },
      ),
      icon: 'Amenities.Booking.CheckinCheckout',
    });
  }
  if (checkOut) {
    lineItems.push({
      title: intl.formatMessage(
        {
          defaultMessage: 'Check out: {value}',
          id: 'w3caJO',
        },
        {
          value: intl.formatTime(dayjs().startOf('day').add(checkOut, 's').toDate()),
        },
      ),
      icon: 'Amenities.Booking.CheckinCheckout',
    });
  }

  // Always show check in with host for campsite if we have check in/out times
  if (checkIn || checkOut) {
    lineItems.push({
      title: intl.formatMessage({
        defaultMessage: 'Check in with the host',
        id: '7MjqmO',
      }),
      icon: 'General.ManageVehicles',
    });
  }

  lineItems.push({
    title: allowsPets
      ? intl.formatMessage({ defaultMessage: 'Pet friendly', id: 'c8hn0D' })
      : intl.formatMessage({ defaultMessage: 'Not pet friendly', id: 'BaTzMN' }),
    icon: 'Amenities.Pets.PetFriendly',
    notAllowed: !allowsPets,
  });

  lineItems.push({
    title: allowsRvs
      ? intl.formatMessage({ defaultMessage: 'Allows RVs', id: 'Msu1CL' })
      : intl.formatMessage({ defaultMessage: 'Does not allow RVs', id: 'JDsJL1' }),
    icon: 'Accomodation.AccomodationType.CamperRv',
    notAllowed: !allowsRvs,
  });

  lineItems.push({
    title: allowsCampfires
      ? intl.formatMessage({ defaultMessage: 'Allows campfires', id: 'IQoUFc' })
      : intl.formatMessage({ defaultMessage: 'Does not allow campfires', id: 'UKb7Ek' }),
    icon: 'Amenities.Outdoor.Firepit',
    notAllowed: !allowsCampfires,
  });

  if (sleeps) {
    lineItems.push({
      title: intl.formatMessage(
        {
          defaultMessage: '{n, plural, one {# person} other {# persons}} maximum overnight',
          id: 'a+Cj+v',
        },
        { n: sleeps },
      ),
      icon: 'General.User.Users',
    });
  }

  return lineItems;
};

export const getStayParkingRules = (state: TRootState) => {
  if (!state.listing.data || !state.listing.data.stay?.parking_onsite) {
    return null;
  }

  const intl = getIntl();

  return {
    icon: PARKING_ONSITE,
    title: intl.formatMessage(
      {
        defaultMessage: '{maxSpaces} parking {hasSpace, select, true {spots} other {spot}}',
        id: '8E7imJ',
      },
      {
        maxSpaces: state.listing.data.stay.max_parking_spaces,
        hasSpace:
          state.listing.data.stay.max_parking_spaces &&
          state.listing.data.stay.max_parking_spaces > 1,
      },
    ),
  };
};

export const getListingMileageRules = createSelector(
  [
    (state: TRootState) => state.listing.data,
    (_: TRootState, includeTiers: boolean) => includeTiers,
    (_: TRootState, __: boolean, skipTotalIncluded: boolean) => skipTotalIncluded,
    (state: TRootState) => getCurrency(state),
    (state: TRootState) => state.checkout.booking,
    (state: TRootState) => state.quote.data,
  ],
  (listing, includeTiers, skipTotalIncluded, currency, booking, quote) => {
    if (listing?.rental_category === 'stay') return null;
    const totalIncluded =
      (!skipTotalIncluded &&
        (booking?.mileage_usage_item?.total_included || quote?.mileage_total_included)) ||
      0;
    return mapDataToListingUsageRules(listing, currency, 'mileage', totalIncluded, includeTiers);
  },
);

export const getListingGeneratorRules = (state: TRootState, skipTotalIncluded = false) => {
  const data = state.listing.data;
  if (data?.rental_category === 'stay') return null;
  const currency = getCurrency(state);
  const booking = state.checkout.booking;
  const quote = state.quote.data;
  const totalIncluded =
    (!skipTotalIncluded &&
      (booking?.generator_usage_item?.total_included || quote?.generator_total_included)) ||
    0;
  return mapDataToListingUsageRules(data, currency, 'generator', totalIncluded);
};

export const getCampsiteCampgroundRules = (state: TRootState) =>
  state.listing.data?.campground?.rules;
