import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { EProtections } from '@/services/types/core/protections';
import { IItem, IQuote } from '@/services/types/core/quotes';
import { formatCurrency } from '@/utility/currency';

import { IBillSummary } from '../listing/bill';
import getHost from '../listing/host';
import { getBookingOrQuoteCurrency } from './data';

const mapQuoteToBillSummary = ({
  currency,
  data,
  dataOrigin,
  totalValue,
  rawTotalValue,
  ownerName,
}: {
  currency: string;
  data: Pick<
    IQuote,
    | 'addons'
    | 'bundle_id'
    | 'delivery_item'
    | 'delivery_usage_item_id'
    | 'discount_code'
    | 'discount_code_amount'
    | 'duration'
    | 'insurance_bundle'
    | 'owner_fees'
    | 'rental_amount_item'
    | 'renter_credits_applied'
    | 'service_fee'
    | 'tax'
    | 'services'
    | 'waiver_summary'
  >;
  dataOrigin: 'booking' | 'quote';
  totalValue: string;
  rawTotalValue: string;
  ownerName?: string;
}): IBillSummary => {
  const {
    addons,
    bundle_id,
    delivery_item,
    delivery_usage_item_id,
    discount_code,
    discount_code_amount,
    duration,
    insurance_bundle,
    owner_fees,
    rental_amount_item,
    renter_credits_applied,
    service_fee,
    tax,
    services,
    waiver_summary,
  } = data;
  const sum = (prop: keyof Partial<{ total: number; count: number }>) =>
    (addons as IItem[])?.reduce((acc: number, curr) => acc + curr[prop], 0) || 0;

  const addonsValue: number | undefined = sum('total');
  const addonsQuantity: number | undefined = sum('count');

  // TODO: should come from the API
  const feesValue = [
    tax,
    service_fee,
    owner_fees?.reduce((acc: number, cur) => acc + cur.total, 0),
  ].reduce<number>((total, fee) => total + (fee || 0), 0);
  const deliveryValue =
    dataOrigin === 'quote' || delivery_usage_item_id ? delivery_item?.price : undefined;
  const insuranceValue = insurance_bundle?.total_price;
  const insuranceBundleId = bundle_id;
  const isCustomInsurance = (insurance_bundle?.owner_id ?? 0) > 0;
  const totalPerDay = rental_amount_item?.total;
  const discountCode = discount_code;
  const discountAmount = discount_code_amount;
  const credit = renter_credits_applied;

  const currencyFormat = { currency, digits: 2 };
  const tripInsurance = services?.find(service => service.category === EProtections.TRIP_INSURANCE);
  const damageProtection = services?.find(
    protection => protection.category === EProtections.DAMAGE_PROTECTION,
  );

  return {
    addonsQuantity,
    addonsValue: addonsValue
      ? formatCurrency({ priceInCents: addonsValue, ...currencyFormat })
      : undefined,
    feesValue: formatCurrency({ priceInCents: feesValue, ...currencyFormat }),
    rawFeesValue: feesValue,
    deliveryValue: deliveryValue
      ? formatCurrency({ priceInCents: deliveryValue, ...currencyFormat })
      : undefined,
    insuranceValue: insuranceValue
      ? formatCurrency({ priceInCents: insuranceValue, ...currencyFormat })
      : undefined,
    insuranceBundleId,
    duration,
    totalPerDay: formatCurrency({ priceInCents: totalPerDay, ...currencyFormat }),
    totalValue,
    rawTotalValue,
    credit: credit > 0 ? formatCurrency({ priceInCents: credit, ...currencyFormat }) : undefined,
    discount:
      discountCode && discountAmount && discountAmount > 0
        ? {
            code: discountCode,
            amount: formatCurrency({ priceInCents: discountAmount, ...currencyFormat }),
          }
        : undefined,
    isCustomInsurance,
    ownerName,
    tripInsurance: tripInsurance
      ? formatCurrency({ priceInCents: tripInsurance.total, ...currencyFormat })
      : undefined,
    damageProtection: damageProtection
      ? formatCurrency({ priceInCents: damageProtection.total, ...currencyFormat })
      : undefined,
    waiverSummary: waiver_summary,
    dataOrigin,
  };
};

export const getBillSummary = createSelector<
  TRootState,
  TRootState['checkout']['booking'],
  TRootState['quote']['data'],
  ReturnType<typeof getBookingOrQuoteCurrency>,
  ReturnType<typeof getHost>,
  IBillSummary | undefined
>(
  state => state.checkout.booking,
  state => state.quote.data,
  getBookingOrQuoteCurrency,
  getHost,
  (bookingData, quoteData, currency, host) => {
    const data = (bookingData || quoteData) as IQuote;
    const dataOrigin = bookingData ? 'booking' : 'quote';
    if (!data) {
      return undefined;
    }
    const securityDeposit = data.security_deposit ? data.security_deposit : 0;
    const totalValue = formatCurrency({
      priceInCents: data ? data.total - (data?.renter_credits_applied ?? 0) : 0,
      currency,
      digits: 2,
    });

    const rawTotalValue =
      (data ? data.total - (data.renter_credits_applied ?? 0) : 0) + securityDeposit;

    return mapQuoteToBillSummary({
      currency,
      ownerName: host?.name,
      data,
      dataOrigin,
      totalValue,
      rawTotalValue: rawTotalValue.toString(),
    });
  },
);
