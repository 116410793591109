import React, { useMemo, useRef, useState } from 'react';

import Text from '@/components/switchback/Text/Text';
import { generateSimpleId } from '@/utility/helpers';
import { normalizeHtml } from '@/utility/html';
import { convertPixelsToRem, getElComputedStyle } from '@/utility/styles';

import css from './TextArea.module.css';

type ITextAreaElement = React.TextareaHTMLAttributes<HTMLTextAreaElement>;

interface ITextAreaProps extends ITextAreaElement {
  'data-testid'?: string;
  /**
   * Initial value of the input.
   * Best used if you plan to use this component under uncontrolled mode.
   */
  defaultValue?: string;
  /**
   * Validation error to be displayed
   */
  error?: string;
  label?: string;
  /**
   * TextArea max-height
   */
  maxRow?: number;
  /**
   * * TextArea min-height
   */
  minRow?: number;
  value?: string;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  /**
   * setValue: Utility method from `react-use-form`
   * see: https://github.com/react-hook-form/react-hook-form/discussions/1710
   *
   * @param name
   * @param value
   * @param config
   */
  setValue?: (name: string, value: string, config: { shouldValidate: boolean }) => void;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      className,
      ['data-testid']: testid,
      defaultValue = '',
      id,
      name,
      label,
      minRow = 3,
      maxRow = 5,
      error,
      required,
      rows,
      value,
      onFocus,
      onBlur,
      onChange,
      setValue,
      ...props
    },
    ref,
  ) => {
    const [internalValue, setInternalValue] = useState(defaultValue);
    const [hasFocus, setHasFocus] = useState(false);
    const hasValue = !!internalValue || !!value;
    const isControlled = typeof value !== 'undefined';
    const inputId = useMemo(() => id || `textarea_${generateSimpleId()}`, [id]);
    const shouldResize = useMemo(() => !rows && minRow !== maxRow, [rows, minRow, maxRow]);
    const resizeableInputRef = useRef<HTMLDivElement>(null);

    // lineHeight is necessary in order to set min-height and
    // max-height according to the number of rows provided
    const lineHeightSize = useMemo(() => {
      if (!resizeableInputRef.current) return 1;
      const lh = getElComputedStyle(resizeableInputRef.current, 'line-height') || '24';
      return convertPixelsToRem(parseInt(lh, 10));
    }, [resizeableInputRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

    // format onInput event in order to pass on value of the div
    // the return needs to be unknow in order to cast the type on each function
    const formatEvent = (e: React.SyntheticEvent): unknown => {
      return Object.assign({}, e, {
        target: {
          value: internalValue,
        },
      });
    };

    return (
      <div className={`${className} relative`}>
        <div
          className={css.container}
          data-testid="textarea-wrapper"
          data-error={String(!!error)}
          data-focused={String(hasFocus)}
          data-empty={String(!hasValue)}>
          {label && (
            <label className={css.label} htmlFor={inputId}>
              {label}
            </label>
          )}
          <textarea
            aria-invalid={!!error}
            className={`w-full h-full outline-none resize-none ${
              shouldResize ? 'sr-only pointer-events-none' : ''
            }`}
            data-testid={testid || `${name}-textarea`}
            id={inputId}
            name={name}
            ref={ref}
            required={required}
            rows={shouldResize ? undefined : rows || minRow || maxRow}
            value={isControlled ? value : internalValue}
            {...props}
            onFocus={e => {
              setHasFocus(true);
              onFocus?.(e);
            }}
            onBlur={e => {
              setHasFocus(false);
              onBlur?.(e);
            }}
            onChange={e => {
              onChange?.(e);
              setInternalValue(e.currentTarget.value);
            }}
          />
          {shouldResize && (
            <div
              aria-hidden
              className="w-full h-full overflow-y-auto outline-none"
              contentEditable
              data-max={maxRow}
              data-min={minRow}
              ref={resizeableInputRef}
              role="textbox"
              suppressContentEditableWarning
              style={{
                minHeight: `${minRow * lineHeightSize}rem`,
                maxHeight: `${maxRow * lineHeightSize}rem`,
              }}
              onFocus={e => {
                setHasFocus(true);
                onFocus?.(formatEvent(e) as React.FocusEvent<HTMLTextAreaElement>);
              }}
              onBlur={e => {
                setHasFocus(false);
                onBlur?.(formatEvent(e) as React.FocusEvent<HTMLTextAreaElement>);
              }}
              onInput={e => {
                onChange?.(formatEvent(e) as React.ChangeEvent<HTMLTextAreaElement>);

                if (resizeableInputRef?.current) {
                  setInternalValue(normalizeHtml(resizeableInputRef.current.innerHTML));
                  name &&
                    setValue &&
                    setValue(name, normalizeHtml(resizeableInputRef.current.innerHTML), {
                      shouldValidate: true,
                    });
                }
              }}>
              {isControlled && value && normalizeHtml(value)}
            </div>
          )}
        </div>
        {error && (
          <Text
            data-testid={`${name}-textarea-error`}
            type="inline"
            className={`${css.error} absolute top-full left-0 mt-1`}>
            {error}
          </Text>
        )}
      </div>
    );
  },
);

TextArea.displayName = 'TextArea';

export default TextArea;
