export const parse24HFormatTimeToSeconds = (time?: string) => {
  if (!time) return undefined;

  if (!/^([01][0-9]|2[0-3]):([0-5][0-9])$/.test(time)) return undefined;

  const [hours, minutes] = time.split(':');
  const parsedHours = parseInt(hours || '', 10) || 0;
  const parsedMinutes = parseInt(minutes || '', 10) || 0;
  return parsedHours * 3600 + parsedMinutes * 60;
};
