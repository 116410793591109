import { createSelector } from 'reselect';

import { TRootState } from '@/redux/rootReducer';
import { ICampgroundData } from '@/services/types/search/campgrounds/id';
import { IOwner } from '@/services/types/search/rentals/id';
import { getIntl } from '@/utility/i18n';

const mapToHost = (owner: IOwner | undefined, campground: ICampgroundData | null | undefined) => {
  if (!owner) {
    return null;
  }

  const intl = getIntl();
  const memberSince = new Date(owner.created);
  const outdoorsyLaunch = new Date('2015-03-05'); // earliest created date in DB
  const year = memberSince > outdoorsyLaunch ? memberSince.getFullYear() : undefined;
  const slug = owner.slug || owner.id;
  const ownerName = owner.business_name || owner.first_name;
  const fallbackDescription = intl.formatMessage(
    {
      defaultMessage:
        'Host bio coming soon! {ownerName} is committed to providing great stays for guests.',
      id: 'ovI4Xq',
    },
    { ownerName },
  );
  const campgroundName = campground?.name;

  return {
    avatarUrl: owner.avatar_url,
    dealer: owner.dealer || false,
    description: owner.business_description || owner.description || fallbackDescription,
    memberSince: year,
    name: ownerName,
    id: owner.id,
    responseTime: owner.average_response_time || 0,
    slug: slug,
    firstName: owner.first_name,
    campgroundName,
  };
};

const getHost = createSelector<
  TRootState,
  IOwner | undefined,
  ICampgroundData | null | undefined,
  ReturnType<typeof mapToHost>
>(
  state => state.listing.data?.owner,
  state => state.listing.data?.campground,
  mapToHost,
);

export default getHost;
